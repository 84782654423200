export interface BeneficiaryItem {
  id: string;
  name: string;
  city: string;
  location: string;
}
export enum BeneficiaryOptionType {
  delete = 'delete',
}

export interface BeneficiaryColumnArgs {
  shownOptions?: Partial<Record<BeneficiaryOptionType, boolean>>;
  editable?: boolean;
  onClickOption: (item: BeneficiaryItem, option: BeneficiaryOptionType) => void;
}
