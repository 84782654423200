// eslint-disable-next-line no-restricted-imports
import {
  BaseLegalClientFirstHeadFragment,
  LegalClientFirstHeadFragment,
  Query_LegalClientLegalHeadFirstHeadController_GetById_Status,
} from 'api/generated/graphql';
import { LegalClientFirstHeadEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseTaxResidenceEntity } from '../client/parseEntity/parseTaxResidenceEntity';
import { parseCountryEntity } from '../parseCountryEntity';
import { parseIdentityDocumentEntity } from '../parseEntity/parseIdentityDocumentEntity';
import { parseKycEntity } from '../parseEntity/parseKycEntity';
import { parsePepConnectionEntity } from '../parseEntity/parsePepConnectionEntity';
import { parseOboardingData } from '../parseOboardingData';

import { parsePositionInCompanyEntity } from './parseEntity';

export const parseLegalClientFirstHeadEntity = (
  data: LegalClientFirstHeadFragment | BaseLegalClientFirstHeadFragment
): LegalClientFirstHeadEntity => ({
  id: data.id,
  versionNumber: data.version?.number,
  versionId: data.version?.id,
  createdAt: new Date(data.createdAt),
  deletedAt: data.deletedAt ? new Date(data.deletedAt) : undefined,
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  middleName: data.middleName || undefined,
  dateOfBirth: new Date(data.dateOfBirth),
  legalClientLegalHeadId: data.legalClientLegalHeadId || undefined,
  onboarding: parseOboardingData(data.onboarding),
  isActive: (data as LegalClientFirstHeadFragment).user?.isActive,
  archived:
    data.status ===
    Query_LegalClientLegalHeadFirstHeadController_GetById_Status.Archived,

  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  registrationAddress: data.registrationAddress,
  residentialAddress: data.residentialAddress,
  pepConnection: parsePepConnectionEntity(data),
  positionInCompany: data.positionInCompany
    ? parsePositionInCompanyEntity(data.positionInCompany)
    : undefined,
  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),
  additionalDocumentFiles: lodash
    .compact(data.additionalDocuments)
    .map(parseFileDocumentEntity),

  kyc: parseKycEntity(data.kyc),

  selfieFiles: data.selfieDocuments
    ? lodash.compact(data.selfieDocuments).map(parseFileDocumentEntity)
    : undefined,
  proofOfResidenceFiles: data.proofOfResidenceDocuments
    ? lodash
        .compact(data.proofOfResidenceDocuments)
        .map(parseFileDocumentEntity)
    : undefined,
  identityDocument: data.identityDocument
    ? parseIdentityDocumentEntity(data.identityDocument)
    : undefined,
});
