import { ErrorLayout, LoadingLayout } from 'components';
import { LegalClientAddBeneficiaryDialogFeature } from 'modules/client/legalClientAddBeneficiary';
import { LegalClientAddHeadDialogFeature } from 'modules/client/legalClientAddHead/dialogs';
import { LegalClientAddLegalHeadDialogFeature } from 'modules/client/legalClientAddLegalHead';

import { useAddDialogs, useLegalClient, useLegalClientStatus } from '../hooks';
import { LegalClientView } from '../views';

export const LegalClientContainer = () => {
  const {
    legalClient,
    loading,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    formError,
    breadcrumbsTitles,
    managers,
    managersLoading,
    onSubmit,
    onClickHead,
    onClickLegalHead,
    onClickBeneficiary,
    onClickBranch,
    onChangeClientType,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
    refetchLegalClient,
    onDeleteBeneficiary,
    onDeleteHead,
    onDeleteLegalHead,
    onAddBody,
    onUpdateBody,
    onArchiveBody,
    onDearchiveBody,
    onDeleteBody,
    onArchiveHead,
    onArchiveLegalHead,
    onDearchiveHead,
    onDearchiveLegalHead,
  } = useLegalClient();

  const {
    fetchAvailableStatuses: fetchStatusesForChange,
    loading: loadingStatuses,
    submitLoading: submitLoadingStatus,
    onChangeOnboardingStatus,
    statuses: statusesForChange,
    onChangeAccountStatus,
    visibleOnboardingStatuses,
  } = useLegalClientStatus(legalClient);

  const {
    dialogAddBeneficiaryDialogProps,
    dialogAddHeadDialogProps,
    dialogAddLegalHeadDialogProps,
    onClickAddBeneficiary,
    onClickAddHead,
    onClickAddLegalHead,
    onSuccessAddBeneficiary,
    onSuccessAddHead,
    onSuccessAddLegalHead,
  } = useAddDialogs(refetchLegalClient);

  if (error || (!legalClient && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClient) {
    return <LoadingLayout />;
  }

  return (
    <>
      <LegalClientView
        beneficiary={{
          unidentifiableBeneficiaryLetterFiles:
            legalClient.unidentifiableBeneficiaryLetterFiles,
          beneficiaries: legalClient.beneficiaries ?? [],
        }}
        bodies={legalClient.bodies ?? []}
        branches={legalClient.branches ?? []}
        breadcrumbsTitles={breadcrumbsTitles}
        countries={countries}
        countriesLoading={countriesLoading}
        currentAccountStatus={legalClient.accountStatus}
        currentOnboardingStatus={legalClient.onboardingStatus}
        fetchStatusesForChange={fetchStatusesForChange}
        heads={legalClient.heads ?? []}
        initialErrors={formError}
        initialValues={initialValues}
        legalHeads={legalClient.legalHeads ?? []}
        loadingStatuses={loadingStatuses}
        lockClientIfPossible={lockClientIfPossible}
        managers={managers}
        managersLoading={managersLoading}
        representativeOnboardingData={legalClient.representativeOnboarding}
        statusesForChange={statusesForChange}
        submitLoading={submitLoading}
        submitLoadingStatus={submitLoadingStatus}
        unlockClient={unlockClient}
        visibleOnboardingStatuses={visibleOnboardingStatuses}
        onAddBody={onAddBody}
        onArchiveBody={onArchiveBody}
        onArchiveHead={onArchiveHead}
        onArchiveLegalHead={onArchiveLegalHead}
        onChangeAccountStatus={onChangeAccountStatus}
        onChangeClientType={onChangeClientType}
        onChangeOnboardingStatus={onChangeOnboardingStatus}
        onClickAddBeneficiary={onClickAddBeneficiary}
        onClickAddHead={onClickAddHead}
        onClickAddLegalHead={onClickAddLegalHead}
        onClickBeneficiary={onClickBeneficiary}
        onClickBranch={onClickBranch}
        onClickHead={onClickHead}
        onClickLegalHead={onClickLegalHead}
        onDearchiveBody={onDearchiveBody}
        onDearchiveHead={onDearchiveHead}
        onDearchiveLegalHead={onDearchiveLegalHead}
        onDeleteBeneficiary={onDeleteBeneficiary}
        onDeleteBody={onDeleteBody}
        onDeleteHead={onDeleteHead}
        onDeleteLegalHead={onDeleteLegalHead}
        onShowVersionHistory={onShowVersionHistory}
        onSubmit={onSubmit}
        onUpdateBody={onUpdateBody}
      />
      <LegalClientAddHeadDialogFeature
        legalClient={legalClient}
        onSuccess={onSuccessAddHead}
        {...dialogAddHeadDialogProps}
      />
      <LegalClientAddBeneficiaryDialogFeature
        legalClient={legalClient}
        onSuccess={onSuccessAddBeneficiary}
        {...dialogAddBeneficiaryDialogProps}
      />
      <LegalClientAddLegalHeadDialogFeature
        legalClient={legalClient}
        onSuccess={onSuccessAddLegalHead}
        {...dialogAddLegalHeadDialogProps}
      />
    </>
  );
};
