// eslint-disable-next-line no-restricted-imports
import { LegalClientBeneficiaryFragment } from 'api/generated/graphql';
import { LegalClientBeneficiaryEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseTaxResidenceEntity } from '../client/parseEntity/parseTaxResidenceEntity';
import { parseCountryEntity } from '../parseCountryEntity';
import { parseIdentityDocumentEntity } from '../parseEntity/parseIdentityDocumentEntity';
import { parseKycEntity } from '../parseEntity/parseKycEntity';
import {
  parsePepConnectionEntity,
  PepConnectionTypeApi,
} from '../parseEntity/parsePepConnectionEntity';
import { parseOboardingData } from '../parseOboardingData';

import { parseControlOverEntity } from './parseEntity';

export const parseLegalClientBeneficiaryEntity = (
  data: Omit<
    LegalClientBeneficiaryFragment,
    '__typename' | 'pepConnectionType' | 'user'
  > & {
    pepConnectionType?: PepConnectionTypeApi | null;
    user?: { isActive?: boolean };
  }
): LegalClientBeneficiaryEntity => ({
  id: data.id,
  versionNumber: data.version?.number,
  versionId: data.version?.id,
  createdAt: new Date(data.createdAt),
  deletedAt: data.deletedAt ? new Date(data.deletedAt) : undefined,
  dateOfBirth: new Date(data.dateOfBirth),
  firstName: data.firstName,
  lastName: data.lastName,
  middleName: data.middleName || undefined,
  legalClientId: data.legalClientId,
  onboarding: parseOboardingData(data.onboarding),
  email: data.email,
  isActive: data.user?.isActive,

  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  placeOfBirth: data.placeOfBirth,
  registrationAddress: data.registrationAddress,
  residentialAddress: data.residentialAddress,
  pepConnection: parsePepConnectionEntity(data),
  controlOver: parseControlOverEntity(data),
  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),
  additionalDocumentFiles: lodash
    .compact(data.additionalDocuments)
    .map(parseFileDocumentEntity),

  kyc: parseKycEntity(data.kyc),

  selfieFiles: data.selfieDocuments
    ? lodash.compact(data.selfieDocuments).map(parseFileDocumentEntity)
    : undefined,
  proofOfResidenceFiles: data.proofOfResidenceDocuments
    ? lodash
        .compact(data.proofOfResidenceDocuments)
        .map(parseFileDocumentEntity)
    : undefined,
  identityDocument: data.identityDocument
    ? parseIdentityDocumentEntity(data.identityDocument)
    : undefined,
});
