import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientHead } from '../hooks';
import { LegalClientHeadView } from '../views/LegalClientHeadView';

export const LegalClientHeadContainer = () => {
  const {
    loading,
    legalClientHead,
    legalClient,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    deleteLoading,
    resendVerificationEmailTime,
    changeEmailLoading,
    changeEmailError,
    archiveLoading,
    dearchiveLoading,
    onChangeEmail,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
    onDelete,
    onSendVerificationEmail,
    onArchive,
    onDearchive,
  } = useLegalClientHead();

  useScrollToTop([]);

  if (error || (!legalClientHead && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientHead) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientHeadView
      archiveLoading={archiveLoading}
      bodies={legalClient?.bodies ?? []}
      breadcrumbsTitles={breadcrumbsTitles}
      changeEmailError={changeEmailError}
      changeEmailLoading={changeEmailLoading}
      countries={countries}
      countriesLoading={countriesLoading}
      dearchiveLoading={dearchiveLoading}
      deleteLoading={deleteLoading}
      initialValues={initialValues}
      kycData={legalClientHead.kyc}
      lockClientIfPossible={lockClientIfPossible}
      resendVerificationEmailTime={resendVerificationEmailTime}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onArchive={onArchive}
      onChangeEmail={onChangeEmail}
      onDearchive={onDearchive}
      onDelete={onDelete}
      onSendVerificationEmail={onSendVerificationEmail}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
