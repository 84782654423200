import { FC, useMemo } from 'react';

import {
  ManagerData,
  OnboardingData,
  OnboardingDataStatus,
} from 'api/types/data';
import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
  CountryEntity,
  FileEntity,
} from 'api/types/entity';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import {
  FormContentWrapper,
  LegalClientTypeToggleButton,
} from 'modules/client/common/components';
import { useLockAndUnlockReadOnly } from 'modules/client/common/hooks';
import { LegalClientType } from 'modules/client/legal/types';

import { useTranslation } from '../../../../../../libs/i18n';
import { ActionProps } from '../../../../common/components/FormContentWrapper/types';
import { renderClientStatus } from '../../../../common/helpers';
import { useFormContext } from '../../../forms/legalClientForm';
import {
  LegalClientSections,
  LegalClientSectionsProps,
} from '../../LegalClientSections';

export interface FormContentProps extends LegalClientSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  heads: LegalClientInnerItemEntity[];
  legalHeads: LegalClientInnerItemEntity[];
  beneficiary: {
    unidentifiableBeneficiaryLetterFiles?: FileEntity[];
    beneficiaries: LegalClientInnerItemEntity[];
  };
  branches: LegalClientInnerItemEntity[];
  submitLoading?: boolean;
  managers: ManagerData[];
  managersLoading?: boolean;
  representativeOnboardingData?: OnboardingData;
  currentAccountStatus: ClientAccountStatusType | null;
  currentOnboardingStatus: ClientOnboardingStatusType;
  visibleOnboardingStatuses?: OnboardingDataStatus[];
  onClickHead: (id: string) => void;
  onClickLegalHead: (id: string) => void;
  onClickBeneficiary: (id: string) => void;
  onClickBranch: (id: string) => void;
  onChangeClientType?: (v: LegalClientType) => void;
  onChangeOnboardingStatus: () => void;
  onChangeAccountStatus: () => void;
  onShowVersionHistory?: () => void;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  currentOnboardingStatus,
  representativeOnboardingData,
  currentAccountStatus,
  lockClientIfPossible,
  unlockClient,
  onChangeClientType,
  onChangeOnboardingStatus,
  onChangeAccountStatus,
  onShowVersionHistory,
  ...sectionsProps
}) => {
  const { resetForm, values, dirty, setReadOnly } = useFormContext();

  const { fullName } = values.companyName;

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );
  const { t } = useTranslation();

  const topContent = useMemo(
    () =>
      renderClientStatus(
        {
          onboarding: representativeOnboardingData,
          status: currentAccountStatus || currentOnboardingStatus,
        },
        t,
        { size: 'smedium', uppercase: true }
      ),
    [
      currentAccountStatus,
      currentOnboardingStatus,
      representativeOnboardingData,
      t,
    ]
  );

  const actionMenuProps: ActionProps | undefined = useMemo(() => {
    if (currentOnboardingStatus === ClientOnboardingStatusType.AccountOpened) {
      if (currentAccountStatus === ClientAccountStatusType.Closed) {
        return undefined;
      }

      return {
        onChangeAccountStatus,
      };
    }

    return {
      onChangeOnboardingStatus,
    };
  }, [
    currentAccountStatus,
    currentOnboardingStatus,
    onChangeAccountStatus,
    onChangeOnboardingStatus,
  ]);

  return (
    <FormContentWrapper
      shownTabBar
      actionMenuProps={actionMenuProps}
      content={<LegalClientSections {...sectionsProps} />}
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      submitLoading={submitLoading}
      TabBarComponent={LegalClientTypeToggleButton}
      tabBarValue={LegalClientType.legal}
      topContent={topContent}
      onChangeTabBarValue={onChangeClientType}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
      onShowVersionHistory={onShowVersionHistory}
    />
  );
};
