import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientBeneficiary } from '../hooks';
import { LegalClientBeneficiaryView } from '../views/LegalClientBeneficiaryView';

export const LegalClientBeneficiaryContainer = () => {
  const {
    loading,
    legalClientBeneficiary,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    deleteLoading,
    resendVerificationEmailTime,
    changeEmailLoading,
    changeEmailError,
    onChangeEmail,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
    onDelete,
    onSendVerificationEmail,
  } = useLegalClientBeneficiary();

  useScrollToTop([]);

  if (error || (!legalClientBeneficiary && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientBeneficiary) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientBeneficiaryView
      breadcrumbsTitles={breadcrumbsTitles}
      changeEmailError={changeEmailError}
      changeEmailLoading={changeEmailLoading}
      countries={countries}
      countriesLoading={countriesLoading}
      deleteLoading={deleteLoading}
      initialValues={initialValues}
      kycData={legalClientBeneficiary.kyc}
      lockClientIfPossible={lockClientIfPossible}
      resendVerificationEmailTime={resendVerificationEmailTime}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onChangeEmail={onChangeEmail}
      onDelete={onDelete}
      onSendVerificationEmail={onSendVerificationEmail}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
