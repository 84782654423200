import {
  useDeleteLegalClientFirstHead,
  useLegalClient,
  useLegalClientFirstHead as useLegalClientFirstHeadApi,
  useLegalClientFirstHeadArchive,
  useLegalClientFirstHeadDearchive,
  useLegalClientFirstHeadSaveEmail,
  useLegalClientFirstHeadSendCorporateAccountVerificationEmail,
  useLegalClientLegalHead,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { PermissionType } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

import { LegalClientFirstHeadFormType } from '../forms/legalClientFirstHeadForm';
import { getLegalClientFirstHeadInitialValue } from '../helpers';

import { useFirstHeadPageTitle } from './useFirstHeadPageTitle';
import { useLockLegalClientFirstHead } from './useLockLegalClientFirstHead';
import { useUpdateLegalClientFirstHead } from './useUpdateLegalClientFirstHead';

type NavigateParams = {
  firstHeadId: string;
  headId: string;
  legalClientId: string;
};

export const useLegalClientFirstHead = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { checkPermissions } = usePermissions();

  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientFirstHead, error } = useLegalClientFirstHeadApi(
    params.firstHeadId!
  );

  const { loading: submitLoading, updateLegalClientFirstHead } =
    useUpdateLegalClientFirstHead();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);
  const { legalClientLegalHead } = useLegalClientLegalHead(params.headId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientFirstHead(
    legalClient,
    legalClientFirstHead
  );

  const {
    data: { resendTime: resendVerificationEmailTime },
    sendVerificationEmail,
    error: verificationEmailError,
  } = useLegalClientFirstHeadSendCorporateAccountVerificationEmail();

  const {
    loading: changeEmailLoading,
    saveEmail,
    error: changeEmailError,
  } = useLegalClientFirstHeadSaveEmail();

  const {
    deleteLegalClientFirstHead,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteLegalClientFirstHead();

  const {
    loading: archiveLoading,
    archive,
    error: archiveError,
  } = useLegalClientFirstHeadArchive();
  const {
    loading: dearchiveLoading,
    dearchive,
    error: dearchiveError,
  } = useLegalClientFirstHeadDearchive();

  const onSubmit: FormSubmit<LegalClientFirstHeadFormType> = async (
    values,
    helpers
  ) => {
    if (await updateLegalClientFirstHead(legalClientFirstHead!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const initialValues = legalClientFirstHead
    ? getLegalClientFirstHeadInitialValue(legalClientFirstHead, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientFirstHead]: legalClientFirstHead
      ? parseFullName(legalClientFirstHead)
      : '',
    [RoutesId.legalClientLegalHead]: legalClientLegalHead?.fullName,
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientFirstHead?.versionNumber &&
    legalClientFirstHead?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientLegalHeadVersion.legalClientFirstHeadVersion.fullPath,
            {
              legalClientId: legalClient.id,
              headId: params.headId!,
              versionId: legalClientFirstHead.versionId!,
              firstHeadId: legalClientFirstHead.id,
            }
          );
        }
      : undefined;

  const onSendVerificationEmail = !legalClientFirstHead?.isActive
    ? async () => {
        await sendVerificationEmail(params.firstHeadId!);
        notify.info(t('common.emailSent'));
      }
    : undefined;

  const onChangeEmail = !legalClientFirstHead?.isActive
    ? async (email: string, reason: string) => {
        if (
          await saveEmail(params.firstHeadId!, {
            email,
            reason,
            versionNumber: legalClientFirstHead?.versionNumber!,
          })
        ) {
          notify.info(t('common.emailSent'));
          return true;
        }
        return false;
      }
    : undefined;

  const onDelete = checkPermissions([
    PermissionType.LegalClientLegalHeadFirstHeadDeleteOne,
  ])
    ? async (reason: string) => {
        if (
          await deleteLegalClientFirstHead({
            id: params.firstHeadId!,
            reason,
            versionNumber: legalClient?.versionNumber!,
          })
        ) {
          notify.info(
            t('client.legal.legalClientFirstHead.successDeleteMessage')
          );
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientLegalHead
              .fullPath,
            {
              headId: params.headId!,
              legalClientId: params.legalClientId!,
            },
            { replace: true }
          );
        }
      }
    : undefined;

  const onArchive = !legalClientFirstHead?.archived
    ? async (reason: string) => {
        if (
          await archive(params.firstHeadId!, {
            reason,
            versionNumber: legalClientFirstHead?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientFirstHead.archivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  const onDearchive = legalClientFirstHead?.archived
    ? async (reason: string) => {
        if (
          await dearchive(params.firstHeadId!, {
            reason,
            versionNumber: legalClientFirstHead?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientFirstHead.dearchivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  useFirstHeadPageTitle(
    legalClient,
    legalClientLegalHead,
    legalClientFirstHead
  );

  useServerErrorNotify(
    verificationEmailError ?? deleteError ?? archiveError ?? dearchiveError
  );

  return {
    legalClientFirstHead,
    legalClientLegalHead,
    loading: !legalClientFirstHead && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    resendVerificationEmailTime,
    changeEmailLoading,
    changeEmailError,
    deleteLoading,
    archiveLoading,
    dearchiveLoading,
    onChangeEmail,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
    onSendVerificationEmail,
    onDelete,
    onArchive,
    onDearchive,
  };
};
