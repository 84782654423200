import { useUpdateClient as useUpdateClientApi } from 'api/requests/client';
import { ClientEntity, ClientOnboardingStatusType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { ClientFormType } from '../forms/clientForm';
import { getClientSubmitData } from '../helpers';

export const useUpdateClient = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { updateClient, loading: submitLoading, error } = useUpdateClientApi();

  const handleUpdateClient = async (
    client: ClientEntity,
    values: ClientFormType
  ) => {
    if (client.versionNumber !== undefined) {
      const params = getClientSubmitData(client.versionNumber, values);
      const updatedClient = await updateClient(client.id, params);

      if (updatedClient) {
        notify.info(t('common.successSave'));

        if (
          updatedClient.onboardingStatus ===
          ClientOnboardingStatusType.AccountOpened
        ) {
          notify.info(t('client.client.statuses.updateBrightText'));
        }
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    submitLoading,
    updateClient: handleUpdateClient,
    error,
  };
};
