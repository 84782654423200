import { FC } from 'react';

import {
  Body,
  NavigationBreadcrumbsTitles,
  LayoutWithNavigationBreadcrumbs,
} from 'components';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { AdminFormContent, AdminFormHeader } from '../components';
import { Form, getSchema, AdminFormType, FormErrors } from '../forms/adminForm';

export interface AdminViewProps {
  initialValues: AdminFormType;
  formError?: FormErrors;
  submitLoading?: boolean;
  isActive?: boolean;
  isDeactivated?: boolean;
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  resendTime?: number;
  onResetPassword: () => void;
  onResendInvite: () => void;
  onSubmit: FormSubmit<AdminFormType>;
  onActivate: () => void;
  onDeactivate: () => void;
}

export const AdminView: FC<AdminViewProps> = ({
  initialValues,
  submitLoading,
  formError,
  breadcrumbsTitles,
  isActive,
  isDeactivated,
  resendTime,
  onResetPassword,
  onResendInvite,
  onSubmit,
  onActivate,
  onDeactivate,
}) => {
  const { t } = useTranslation();
  return (
    <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
      <Form
        initialReadOnly
        initialShowCopyButton
        initialErrors={formError}
        initialValues={initialValues}
        validationSchema={getSchema(t)}
        onSubmit={onSubmit}
      >
        <Stack spacing={32}>
          <AdminFormHeader
            isActive={isActive}
            isDeactivated={isDeactivated}
            resendTime={resendTime}
            submitLoading={submitLoading}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
            onResendInvite={onResendInvite}
            onResetPassword={onResetPassword}
          />
          <Body>
            <AdminFormContent />
          </Body>
        </Stack>
      </Form>
    </LayoutWithNavigationBreadcrumbs>
  );
};
