import {
  useClient,
  useRepresentativeClient as useRepresentativeClientApi,
} from 'api/requests/';
import { useCountries } from 'api/requests/country';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, RoutesId } from 'libs/navigation';

import { RepresentativeFormType } from '../forms/representativeForm';
import { getRepresentativeInitialValue } from '../helpers';

import { useClientPageTitle } from './useClientPageTitle';
import { useLockRepresentativeClient } from './useLockRepresentativeClient';
import { useUpdateRepresentativeClient } from './useUpdateRepresentativeClient';

type RepresentativeClientContainerParams = {
  representativeId: string;
  clientId: string;
};

export const useRepresentativeClient = () => {
  const { t } = useTranslation();

  const { params, navigate } =
    useNavigate<RepresentativeClientContainerParams>();

  const { loading: loadingClient, client } = useClient(params.clientId!);

  const {
    loading: loadingRepresentativeClient,
    representativeClient,
    error,
  } = useRepresentativeClientApi(params.representativeId!);

  const loading = loadingClient || loadingRepresentativeClient;

  const { countries, loading: countriesLoading } = useCountries();

  const { updateClient, submitLoading } = useUpdateRepresentativeClient(client);

  const { lockClientIfPossible, unlockClient } =
    useLockRepresentativeClient(representativeClient);

  useClientPageTitle(client, representativeClient);

  const initialValues = representativeClient
    ? getRepresentativeInitialValue(representativeClient, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.personClient]: client ? parseFullName(client) : '',
    [RoutesId.representativeClient]: representativeClient
      ? parseFullName(representativeClient)
      : '',
  };

  const onSubmit: FormSubmit<RepresentativeFormType> = async (
    values,
    helpers
  ) => {
    if (await updateClient(representativeClient!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onChangeClientType = () => {
    navigate(
      ROUTES.clients.personClients.personClient.fullPath,
      {
        clientId: representativeClient!.clientId,
      },
      { replace: true }
    );
  };

  const onShowVersionHistory =
    representativeClient?.versionNumber &&
    representativeClient?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.personClients.personClient.personClientVersion
              .representativeClientVersion.fullPath,
            {
              clientId: params.clientId!,
              representativeId: params.representativeId!,
              versionId: representativeClient?.versionId!,
            },
            { replace: true }
          );
        }
      : undefined;

  return {
    client,
    representativeClient,
    loading: !representativeClient && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onChangeClientType,
    onShowVersionHistory,
  };
};
