import { useUpdateRepresentativeClient as useUpdateRepresentativeClientApi } from 'api/requests/';
import {
  ClientEntity,
  ClientOnboardingStatusType,
  RepresentativeClientEntity,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { RepresentativeFormType } from '../forms/representativeForm';
import { getSubmitData } from '../helpers';

export const useUpdateRepresentativeClient = (client: ClientEntity | null) => {
  const notify = useNotify();
  const { t } = useTranslation();
  const {
    updateClient,
    loading: submitLoading,
    error,
  } = useUpdateRepresentativeClientApi();

  const handleUpdateClient = async (
    representativeClient: RepresentativeClientEntity,
    values: RepresentativeFormType
  ) => {
    if (representativeClient.versionNumber !== undefined) {
      const params = getSubmitData(representativeClient.versionNumber, values);
      if (await updateClient(representativeClient.id, params)) {
        notify.info(t('common.successSave'));

        if (
          client?.onboardingStatus === ClientOnboardingStatusType.AccountOpened
        ) {
          notify.info(t('client.client.statuses.updateBrightText'));
        }
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    submitLoading,
    updateClient: handleUpdateClient,
    error,
  };
};
