import { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';

import { ActionMenu, Body, ContentHeader } from 'components';
import { lodash } from 'helpers';
import { useFormErrorFocus, useWindowUnload } from 'hooks';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { ROUTES, usePrompt } from 'libs/navigation';
import { Button, MenuItem, Stack } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { ReasonChangeFormType } from '../../forms/reasonChangeForm';
import { DialogReasonChange } from '../DialogReasonChange';

import { ContentStyled } from './styles';
import { ActionType, FormContentWrapperProps } from './types';

export const FormContentWrapper = <T extends string | number>({
  shownTabBar,
  content,
  fullName,
  dirty,
  tabBarValue,
  submitLoading,
  TabBarComponent,
  actionMenuProps,
  topContent,
  headerRightContent,
  resetForm,
  onEnableReadOnly,
  onChangeTabBarValue,
  onEdit,
  onShowVersionHistory,
}: FormContentWrapperProps<T>) => {
  const { t } = useTranslation();

  const {
    isOpen: isOpenDialogReason,
    onClose: onCloseDialogReason,
    open: openDialogReason,
  } = useDialog();

  const {
    readOnly,
    submitDisabled,
    handleSubmit,
    setFieldValue,
    validateForm,
  } = useFormContext<ReasonChangeFormType>();

  const { formErrorFocus } = useFormErrorFocus();

  const editable = !readOnly;

  const confirmIsChanged = useCallback(
    (f: () => void) => {
      if (!dirty) {
        f();
        // eslint-disable-next-line no-alert
      } else if (window.confirm(t('confirm.cancelSaveMessage'))) {
        f();
      }
    },
    [dirty, t]
  );

  const disableEditable = useCallback(() => {
    confirmIsChanged(() => onEnableReadOnly());
  }, [confirmIsChanged, onEnableReadOnly]);

  const handleChangeClientType = (event: MouseEvent<HTMLElement>, value: T) => {
    confirmIsChanged(() => {
      onChangeTabBarValue?.(value);
    });
  };

  useEffect(() => {
    if (readOnly) {
      resetForm();
    }
  }, [readOnly, resetForm]);

  useWindowUnload(
    (e) => {
      if (dirty && editable) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    () => {
      if (editable) {
        onEnableReadOnly();
      }
    }
  );

  usePrompt(
    t('confirm.cancelSaveMessage'),
    !!dirty,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(() => onEnableReadOnly(), [])
  );

  const editableRef = useRef(editable);

  useEffect(() => {
    editableRef.current = editable;
  }, [editable]);

  useEffect(
    () => () => {
      if (editableRef.current) {
        onEnableReadOnly();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const menuAction = useMemo(
    () =>
      lodash.compact([
        onShowVersionHistory && {
          value: ActionType.showVersionHistory,
          label: t('client.client.versions.showVersionHistoryLabel'),
          Icon: Icon.History,
        },
        actionMenuProps?.showEditAction !== false && {
          value: ActionType.edit,
          label: t('common.edit'),
          Icon: Icon.Edit,
        },
        actionMenuProps?.onChangeOnboardingStatus && {
          value: ActionType.changeOnboardingStatus,
          label: t('client.changeOnboardingStatusLabel'),
          Icon: Icon.Refresh,
        },
        actionMenuProps?.onChangeAccountStatus && {
          value: ActionType.changeAccountStatus,
          label: t('client.changeAccountStatusLabel'),
          Icon: Icon.Refresh,
        },
        actionMenuProps?.onChangeEmail && {
          value: ActionType.changeEmail,
          label: t('client.changeEmailLabel'),
          Icon: Icon.Edit,
        },

        actionMenuProps?.onArchive && {
          value: ActionType.archive,
          label: t('common.archive'),
          Icon: Icon.TimeSleep,
        },
        actionMenuProps?.onDearchive && {
          value: ActionType.dearchive,
          label: t('common.dearchive'),
          Icon: Icon.Lightning,
        },
        actionMenuProps?.onDelete && {
          value: ActionType.delete,
          label: t('common.delete'),
          Icon: Icon.Bucket,
          color: Colors.red100,
        },
      ]),
    [actionMenuProps, onShowVersionHistory, t]
  );

  const onClickOption = (action: MenuItem<ActionType>) => {
    switch (action.value) {
      case ActionType.showVersionHistory:
        onShowVersionHistory?.();
        break;
      case ActionType.edit:
        onEdit();
        break;
      case ActionType.changeOnboardingStatus:
        actionMenuProps?.onChangeOnboardingStatus?.();
        break;
      case ActionType.changeAccountStatus:
        actionMenuProps?.onChangeAccountStatus?.();
        break;
      case ActionType.delete:
        actionMenuProps?.onDelete?.();
        break;
      case ActionType.changeEmail:
        actionMenuProps?.onChangeEmail?.();
        break;
      case ActionType.archive:
        actionMenuProps?.onArchive?.();
        break;
      case ActionType.dearchive:
        actionMenuProps?.onDearchive?.();
        break;
      default:
        break;
    }
  };

  const onSaveDialogReason = (reason: string) => {
    onCloseDialogReason();
    setFieldValue('reasonChange', { reason });
    // need to call with a delay so that the field has time to record
    setTimeout(handleSubmit, 0);
  };

  const handleOnSave = async () => {
    const validateResult = await validateForm();

    if (validateResult) {
      const invalidKeys = Object.keys(validateResult).filter(
        (v) => v !== 'reasonChange'
      );
      if (invalidKeys.length) {
        formErrorFocus();
        return;
      }
    }
    openDialogReason();
  };

  return (
    <Stack spacing={32}>
      <ContentHeader
        canBack
        backPath={ROUTES.clients.fullPath}
        header={editable ? t('client.client.editTitle') : fullName}
        rightContent={
          <Stack alignItems="center" direction="row" spacing={20}>
            {headerRightContent}
            {editable ? (
              <Button
                disabled={submitDisabled}
                label={t('common.save')}
                loading={submitLoading}
                onClick={handleOnSave}
              />
            ) : (
              menuAction.length > 0 && (
                <ActionMenu
                  anchorOrigin={{ horizontal: 'right', vertical: 40 }}
                  label={t('common.actionLabel')}
                  options={menuAction}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClickOption={onClickOption}
                />
              )
            )}
          </Stack>
        }
        onBack={editable ? disableEditable : undefined}
      />
      <Stack alignItems="center" direction="row" spacing={32}>
        {shownTabBar && !!TabBarComponent && (
          <TabBarComponent
            value={tabBarValue}
            onChange={handleChangeClientType}
          />
        )}
        {topContent}
      </Stack>

      <ContentStyled direction="row">
        <Body className="clients-content">{content}</Body>
      </ContentStyled>

      <DialogReasonChange
        isOpen={isOpenDialogReason}
        onClose={onCloseDialogReason}
        onSave={onSaveDialogReason}
      />
    </Stack>
  );
};
