import { ManagerData } from 'api/types/data';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/accountCodesForm';

interface Props {
  managers: ManagerData[];
  managersLoading?: boolean;
}

export const AccountCodesSection = ({ managers, managersLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.client.accountCodes.title')}>
      <Field.Text
        label={t('client.client.accountCodes.code.label')}
        name="accountCodes.code"
        placeholder={t('client.client.accountCodes.code.placeholder')}
      />
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.accountCodes.internalAccountNumber.label')}
          name="accountCodes.internalAccountNumber"
          placeholder={t(
            'client.client.accountCodes.internalAccountNumber.placeholder'
          )}
        />
        <Field.DatePicker
          fullWidth
          label={t(
            'client.client.accountCodes.internalAccountNumberOpeningDate.label'
          )}
          name="accountCodes.internalAccountNumberOpeningDate"
          placeholder={t(
            'client.client.accountCodes.internalAccountNumberOpeningDate.placeholder'
          )}
        />
      </Row>

      <Row>
        <Field.Text
          fullWidth
          label={t(
            'client.client.accountCodes.centralDepositoryAccountNumber.label'
          )}
          name="accountCodes.centralDepositoryAccountNumber"
          placeholder={t(
            'client.client.accountCodes.centralDepositoryAccountNumber.placeholder'
          )}
        />

        <Field.DatePicker
          fullWidth
          label={t(
            'client.client.accountCodes.centralDepositoryAccountNumberOpeningDate.label'
          )}
          name="accountCodes.centralDepositoryAccountNumberOpeningDate"
          placeholder={t(
            'client.client.accountCodes.centralDepositoryAccountNumberOpeningDate.placeholder'
          )}
        />
      </Row>

      <Field.Autocomplete
        label={t('client.client.accountCodes.manager.label')}
        loading={managersLoading}
        name="accountCodes.manager"
        optionLabelKey="name"
        options={managers}
        optionValueKey="id"
        placeholder={t('client.client.accountCodes.manager.placeholder')}
      />
    </Section>
  );
};
