import { FC } from 'react';

import { parseFullName } from 'helpers';
import {
  FormContentWrapper,
  LegalClientTypeToggleButton,
} from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';
import { LegalClientType } from 'modules/client/legal/types';

import { useFormContext } from '../../../forms/legalRepresentativeForm';
import {
  LegalRepresentativeSections,
  LegalRepresentativeSectionsProps,
} from '../../LegalRepresentativeSections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    LegalRepresentativeSectionsProps {
  submitLoading?: boolean;
  onChangeClientType?: (v: LegalClientType) => void;
  onShowVersionHistory?: () => void;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  lockClientIfPossible,
  onChangeClientType,
  unlockClient,
  onShowVersionHistory,
  ...sectionsProps
}) => {
  const { resetForm, values, dirty, setReadOnly } = useFormContext();

  const fullName = parseFullName(values.personalData);

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  return (
    <FormContentWrapper
      shownTabBar
      content={<LegalRepresentativeSections {...sectionsProps} />}
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      submitLoading={submitLoading}
      TabBarComponent={LegalClientTypeToggleButton}
      tabBarValue={LegalClientType.representative}
      onChangeTabBarValue={onChangeClientType}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
      onShowVersionHistory={onShowVersionHistory}
    />
  );
};
