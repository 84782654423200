import { useEffect, useRef, useState } from 'react';

import { lodash } from 'helpers';
import { Input, InputProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface TextFieldProps
  extends FieldType,
    Omit<InputProps, 'name' | 'onChange' | 'onChangeText' | 'value' | 'type'> {
  maxNumber?: number;
  checkNumber?: (value: string | undefined) => boolean;
  format?: (v?: string) => string | undefined;
}

export const NumberField = ({
  name,
  allowSubmit,
  maxNumber,
  readOnly,
  checkNumber,
  format,
  ...props
}: TextFieldProps) => {
  const { field, helpers, inputProps, meta } = useField<string | undefined>(
    name,
    {
      allowSubmit,
    }
  );

  const [value, setValue] = useState(field.value);

  const bounce = useRef(lodash.debounce(helpers.setValue, 200)).current;

  useEffect(() => {
    if (value === undefined) {
      setValue(field.value ?? '');
    }
  }, [field.value, value]);

  useEffect(() => {
    // set the value when changing the name
    setValue(field.value ?? '');
  }, [field.value, name]);

  return (
    <Input
      {...props}
      value={value}
      onBlur={field.onBlur(name)}
      onChange={() => {
        if (meta.isError) {
          helpers.setError('');
        }
      }}
      onChangeText={(v) => {
        if (
          !v ||
          (/^[-\d]\d*$/.test(v) &&
            (maxNumber === undefined || Number(v) <= maxNumber) &&
            (!checkNumber || checkNumber(v)))
        ) {
          const value = format ? format?.(v) : v;
          setValue(value);
          bounce(value);
        }
      }}
      {...inputProps}
      readOnly={readOnly || inputProps.readOnly}
    />
  );
};
