import {
  useCreateLegalClientBody,
  useDeleteLegalClientBody,
  useLegalClientBodyArchive,
  useLegalClientBodyDearchive,
  useUpdateLegalClientBody,
} from 'api/requests';
import { LegalClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

export const useBodyAction = (
  legalClient: LegalClientEntity | null | undefined,
  refetchLegalClient: () => void
) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { createLegalClientBody, error: createError } =
    useCreateLegalClientBody();
  const { updateLegalClientBody, error: updateError } =
    useUpdateLegalClientBody();
  const { archive } = useLegalClientBodyArchive();
  const { dearchive, error: dearchiveError } = useLegalClientBodyDearchive();
  const { deleteLegalClientBody } = useDeleteLegalClientBody();

  const handleSuccess = async (message: string) => {
    await refetchLegalClient();
    notify.info(message);
  };

  const onAddBody = async (
    body: { name: string; type: string },
    reason: string
  ) => {
    await createLegalClientBody({
      name: body.name,
      legalClientId: legalClient!.id,
      reason,
      type: body.type,
      versionNumber: legalClient!.versionNumber!,
    });
    await handleSuccess(t('client.legal.companyStructure.addedMessage'));
  };

  const onUpdateBody = async (
    id: string,
    body: { name: string; type: string },
    reason: string
  ) => {
    await updateLegalClientBody(id, {
      name: body.name,
      reason,
      type: body.type,
      versionNumber: legalClient!.versionNumber!,
    });
    await handleSuccess(t('client.legal.companyStructure.updatedMessage'));
  };

  const onArchiveBody = async (id: string, reason: string) => {
    await archive(id, { reason, versionNumber: legalClient!.versionNumber! });
    await handleSuccess(t('client.legal.companyStructure.archivedMessage'));
  };

  const onDearchiveBody = async (id: string, reason: string) => {
    await dearchive(id, { reason, versionNumber: legalClient!.versionNumber! });
    await handleSuccess(t('client.legal.companyStructure.dearchivedMessage'));
  };

  const onDeleteBody = async (id: string, reason: string) => {
    await deleteLegalClientBody({
      id,
      reason,
      versionNumber: legalClient!.versionNumber!,
    });
    await handleSuccess(t('client.legal.companyStructure.deletedMessage'));
  };

  useServerErrorNotify(updateError ?? createError ?? dearchiveError);

  return {
    onAddBody,
    onUpdateBody,
    onArchiveBody,
    onDearchiveBody,
    onDeleteBody,
  };
};
