import { MoreMenu } from 'components/MoreMenu';
import { lodash } from 'helpers';
import { TFunc } from 'libs/i18n';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { BeneficiaryItem, BeneficiaryOptionType } from '../types';

export const renderMore = (
  t: TFunc,
  item: BeneficiaryItem,
  shownOptions: Partial<Record<BeneficiaryOptionType, boolean>>,
  onClickOption: (item: BeneficiaryItem, option: BeneficiaryOptionType) => void
) => (
  <MoreMenu
    options={lodash.compact([
      shownOptions.delete && {
        value: BeneficiaryOptionType.delete,
        label: t('common.delete'),
        Icon: Icon.Bucket,
        color: Colors.red,
      },
    ])}
    variant="horizontal"
    onClickOption={(option) => onClickOption(item, option.value)}
  />
);
