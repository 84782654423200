export enum PermissionType {
  AdminList = 'AdminList',
  AdminRead = 'AdminRead',
  AdminEdit = 'AdminEdit',
  AdminCreate = 'AdminCreate',
  AdminResetPassword = 'AdminResetPassword',
  AdminResendInvite = 'AdminResendInvite',
  AdminActivate = 'AdminActivate',
  AdminDeactivate = 'AdminDeactivate',
  LegalClientBeneficiaryDeleteOne = 'LegalClientBeneficiaryDeleteOne',
  LegalClientHeadDeleteOne = 'LegalClientHeadDeleteOne',
  LegalClientLegalHeadDeleteOne = 'LegalClientLegalHeadDeleteOne',
  LegalClientLegalHeadFirstHeadDeleteOne = 'LegalClientLegalHeadFirstHeadDeleteOne',
  LegalClientBodyDeleteOne = 'LegalClientBodyDeleteOne',
}
