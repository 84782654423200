import { useMemo } from 'react';

import { lodash } from 'helpers';
import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { renderMore } from './renders/more';
import { BeneficiaryColumnArgs, BeneficiaryItem } from './types';

export const getColumns = (
  t: TFunc,
  { shownOptions, editable, onClickOption }: BeneficiaryColumnArgs
): TableColumn<BeneficiaryItem>[] => {
  const isShowMore = shownOptions && Object.values(shownOptions).some((v) => v);

  return lodash.compact([
    {
      field: 'id',
      title: t('client.legal.companyBeneficiaries.columns.id'),
      width: 110,
      align: 'center',
    },
    {
      field: 'name',
      title: t('client.legal.companyBeneficiaries.columns.name'),
      flex: 1,
    },
    {
      field: 'city',
      title: t('client.legal.companyBeneficiaries.columns.city'),
      flex: 1,
    },
    {
      field: 'location',
      title: t('client.legal.companyBeneficiaries.columns.location'),
      flex: 1,
    },
    editable && isShowMore
      ? {
          field: 'more' as 'id',
          title: '',
          width: 56,
          align: 'center',
          renderCell: (item) =>
            renderMore(t, item, shownOptions, onClickOption),
        }
      : undefined,
  ]);
};

export const useColumns = (props: BeneficiaryColumnArgs) => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t, props), [props, t]);
};
