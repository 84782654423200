import { renderCopyHover } from 'helpers';
import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { ClientsListItem } from '../../types';

import { renderStatus } from './renders';

export const getColumns = (t: TFunc): TableColumn<ClientsListItem>[] => [
  {
    field: 'id',
    title: t('client.clients.legal.columns.id'),
    width: 110,
    renderCell: (item) => renderCopyHover(item.id),
  },
  {
    field: 'code' as 'id',
    title: t('client.clients.legal.columns.code'),
    width: 110,
  },
  {
    field: 'fullName',
    title: t('client.clients.legal.columns.fullName'),
    sortable: true,
    width: 268,
    renderCell: (item) => renderCopyHover(item.fullName),
  },

  {
    field: 'internalAccount' as 'fullName',
    title: t('client.clients.legal.columns.internalAccount'),
    width: 155,
  },
  {
    field: 'accountCentralBank' as 'fullName',
    title: t('client.clients.legal.columns.accountCentralBank'),
    width: 128,
  },
  {
    field: 'email',
    title: t('client.clients.legal.columns.email'),
    width: 230,
    renderCell: (item) => renderCopyHover(item.email),
  },
  {
    field: 'phone',
    title: t('client.clients.legal.columns.phone'),
    width: 180,
    renderCell: (item) => renderCopyHover(item.phone),
  },
  {
    field: 'countryRegistration',
    title: t('client.clients.legal.columns.countryRegistration'),
    width: 156,
    renderCell: (item) => renderCopyHover(item.countryRegistration),
  },
  {
    field: 'location',
    title: t('client.clients.legal.columns.location'),
    width: 144,
    renderCell: (item) => renderCopyHover(item.location),
  },
  {
    field: 'onboardingStatus',
    title: t('client.clients.legal.columns.status'),
    sortable: true,
    minWidth: 365,
    renderCell: (item) => renderStatus(item, t),
  },
];

export const useColumns = () => {
  const { t } = useTranslation();
  return getColumns(t);
};
