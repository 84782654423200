import { ManagerData } from 'api/types/data';
import {
  ObjectSchema,
  string,
  object,
  TypedForm,
  date,
  FormErrors as FormErrorsBase,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface AccountCodesFormType {
  accountCodes: {
    code?: string | null;
    internalAccountNumber?: string | null;
    internalAccountNumberOpeningDate?: Date | null;
    centralDepositoryAccountNumber?: string | null;
    centralDepositoryAccountNumberOpeningDate?: Date | null;
    manager?: Maybe<ManagerData>;
  };
}

export const accountCodesSchema: ObjectSchema<AccountCodesFormType> = object({
  accountCodes: object({
    code: string().nullable(),
    internalAccountNumber: string().max(200).nullable(),
    internalAccountNumberOpeningDate: date().nullable(),
    centralDepositoryAccountNumber: string().nullable(),
    centralDepositoryAccountNumberOpeningDate: date().nullable(),
    manager: object({
      id: string().required(),
      name: string().required(),
    })
      .nullable()
      .default(null),
  }).default({}),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<AccountCodesFormType>();

export type FormErrors = FormErrorsBase<AccountCodesFormType>;
